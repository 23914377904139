import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FC } from "react";

const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Section = styled(Box)`
  margin-bottom: 24px;
`;

const SectionTitle = styled(Typography)`
  font-weight: bold;
  margin-bottom: 8px;
`;

type Props = {
  isOpen: boolean;
  onModalChange: (open: boolean) => void;
};

export const HelpModal: FC<Props> = ({ isOpen, onModalChange }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => onModalChange(false)}
      maxWidth="sm"
      fullWidth
    >
      <StyledDialogTitle>
        使い方ガイド
        <IconButton onClick={() => onModalChange(false)}>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent>
        <Section>
          <SectionTitle variant="h6">基本的な使い方</SectionTitle>
          <Typography>
            1. 出発地となる駅名を入力
            <br />
            2. 到達時間をスライダーで調整（5分〜300分）
            <br />
            3. 乗換回数の条件を選択
            <br />
            4. 検索ボタンを押す
            <br />            
            5. 地図上に結果が表示されます
          </Typography>
        </Section>

        <Section>
          <SectionTitle variant="h6">地図の見方</SectionTitle>
          <Typography>
            • マーカー：到達可能な駅の場所と所要時間を示します。マーカーが丸いときは複数の駅がまとめられおり、丸を押すとマップが拡大します
            <br />
            • 青い線：大まかな路線図を示します
            <br />
            検索結果は自動的に最適な縮尺で表示されます
          </Typography>
        </Section>

        <Section>
          <SectionTitle variant="h6">その他機能</SectionTitle>
          <Typography>
            • 検索履歴：過去の検索条件を保存、クリックで再検索できます
          </Typography>
        </Section>

        <Section>
          <Typography>
            問題や要望がありましたら、問い合わせボタンからご連絡ください
          </Typography>
        </Section>
      </DialogContent>
    </Dialog>
  );
};
